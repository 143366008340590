<template>
  <div class="container" id="top-page">
    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="Header"
          >Equipment List</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search1"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="headerTitle"
            :items="headerItemsFilterDate"
            class="elevation-1 table-header"
            @click:row="handleRowClick"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Equipment Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in headerTitle" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              color="black"
                              v-if="checkIsEmpty(curItem[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curItem[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="seeDetail">
                      See Locations
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in headerTitle"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div
                  class="checkbox_container text-start"
                  v-bind:key="item[header.value]"
                >
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    class="chckbox"
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel id="detail" style="margin-top: 30px">
        <v-expansion-panel-header class="Header"
          >Equipment Locations</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loadingDtl"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search2"
          />
          <br />
          <tableComponent
            :tableHeaders="detailTitle"
            :tableItems="detailItemsFilterDate"
          >
          </tableComponent>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import tableComponent from "../../table/tableComponent.vue";
import DecodeJWT from "../DecodeJWT";
import Loading from "../../loading/Loading.vue";
import { callBAQ } from "../../../store/Method/index";

export default {
  components: { tableComponent, Loading },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
      } else {
        window.scrollTo({
          top: document.getElementById("top-page").offsetTop,
          left: 0,
          behavior: "smooth",
        });
        this.panel = Array.from(Array(3).keys());
        this.getBAQHeader();
        const today = new Date();
        const fromday = new Date();
        fromday.setMonth(fromday.getMonth() - 3);
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, "0");
        let day = today.getDate().toString().padStart(2, "0");
        this.todate1 = this.todate2 = `${year}-${month}-${day}`;
        year = fromday.getFullYear();
        month = (fromday.getMonth() + 1).toString().padStart(2, "0");
        day = fromday.getDate().toString().padStart(2, "0");
        this.fromdate1 = this.fromdate2 = `${year}-${month}-${day}`;
      }
    }
  },
  data() {
    return {
      headersNotfound: [{ text: "", value: "nf" }],
      itemNotfound: [{ nf: "Data not found!" }],
      headerTitle: [],
      detailTitle: [],
      headerItems: [],
      detailItems: [],
      panel: [],
      curItem: {},
      dialog: false,
      emptyItem: " ",
      search1: "",
      search2: "",
      fromdate1: "",
      todate1: "",
      fromdate2: "",
      todate2: "",
      loading: false,
      loadingDtl: false,
    };
  },
  watch: {},
  computed: {
    headerItemsFilter() {
      const filterKey = (this.search1 || "").toLowerCase();
      if (this.headerItems == null || filterKey === "") return this.headerItems;
      return this.headerItems.filter((i) => {
        return i.Equip_EquipID.toString().toLowerCase().indexOf(filterKey) > -1;
      });
    },
    headerItemsFilterDate() {
      if (this.headerItemsFilter.length > 0) {
        // let from = new Date(this.fromdate1);
        // let to = new Date(this.todate1)
        // return this.headerItemsFilter.filter((i) => {
        //   let day = i.JobHead_DueDate.split('/')[0];
        //   let month = i.JobHead_DueDate.split('/')[1];
        //   let year = i.JobHead_DueDate.split('/')[2];
        //   let date = new Date(year, month-1, day);
        //   return date <= to && from <= date;
        // });
        return this.headerItemsFilter;
      } else {
        return this.headerItemsFilter;
      }
    },
    detailItemsFilter() {
      const filterKey = (this.search2 || "").toLowerCase();
      if (this.detailItems == null || filterKey === "") return this.detailItems;
      return this.detailItems.filter((i) => {
        return (
          i.EquipLoc_Description.toString().toLowerCase().indexOf(filterKey) >
            -1 ||
          i.EquipLoc_LocID.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    detailItemsFilterDate() {
      if (this.detailItemsFilter.length > 0) {
        // let from = new Date(this.fromdate2);
        // let to = new Date(this.todate2);
        // return this.detailItemsFilter.filter((i) => {
        //   let day = i.HDCase_CreatedDate.split("/")[0];
        //   let month = i.HDCase_CreatedDate.split("/")[1];
        //   let year = i.HDCase_CreatedDate.split("/")[2];
        //   let date = new Date(year, month - 1, day);
        //   return date <= to && from <= date;
        // });
        return this.detailItemsFilter;
      } else {
        return this.detailItemsFilter;
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    checkIsEmpty(value) {
      if (typeof value === "string") {
        if (value.length == 0) return true;
        return false;
      }
      if (typeof value === "boolean") {
        return false;
      }
      return false;
    },
    seeDetail() {
      this.dialog = false;
      this.getBAQDetail(this.curItem);
    },
    handleRowClick(item) {
      this.curItem = item;
      this.dialog = true;
    },
    isBoolean(value) {
      return typeof value === "boolean";
    },
    convertDate(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      } else return "";
    },
    loadData(data) {
      let item = [],
        header = [];
      for (var title in data[0]) {
        header.push({
          text: title.split("_")[1],
          value: title,
          sortable: false,
        });
      }
      if (data && data.length > 0) {
        data.forEach((el) => {
          let i = {};
          for (var e in el) {
            if (e.includes("Date")) i[e] = this.convertDate(el[e]);
            else i[e] = el[e];
          }
          item.push(i);
        });
      }
      return { h: header, i: item };
    },
    setResize() {
      var tables = document.getElementsByTagName("table");
      for (var i = 0; i < tables.length; i++) {
        this.resizableGrid(tables[i]);
      }
    },
    resizableGrid(table) {
      var row = table.getElementsByTagName("tr")[0],
        cols = row.getElementsByClassName("text-start");
      if (!cols) return;
      table.style.overflow = "hidden";
      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(50);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        setListeners(div, i, table);
      }
      function setListeners(div, i, table) {
        var pageX, curCol, curColWidth;
        div.addEventListener("mousedown", function (e) {
          curCol = e.target.parentElement;
          pageX = e.pageX;
          var padding = paddingDiff(curCol);
          curColWidth = curCol.offsetWidth - padding;
        });
        div.addEventListener("mouseover", function (e) {
          e.target.style.borderRight = "2px solid gray";
        });
        div.addEventListener("mouseout", function (e) {
          e.target.style.borderRight = "";
        });
        document.addEventListener("mousemove", function (e) {
          if (curCol) {
            var diffX = e.pageX - pageX;
            let rowDtl = table.getElementsByTagName("tr");
            for (var z = 1; z < rowDtl.length; z++) {
              let el = rowDtl[z].getElementsByTagName("td")[i];
              el.style.removeProperty("max-width");
            }
            curCol.style.setProperty(
              "min-width",
              curColWidth + diffX + "px",
              "important"
            );
          }
        });
        div.addEventListener("dblclick", function (e) {
          curCol = e.target.parentElement;
          curCol.style.setProperty("min-width", "max-content", "important");

          let rowDtl = table.getElementsByTagName("tr");
          for (var z = 1; z < rowDtl.length; z++) {
            let el = rowDtl[z].getElementsByTagName("td")[i];
            el.style.removeProperty("min-width");
            el.style.removeProperty("max-width");
            el.style.setProperty("max-width", "fit-content", "important");
            el.style.setProperty("min-width", "fit-content", "important");
          }

          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
        document.addEventListener("mouseup", function (e) {
          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
      }
      function createDiv(height) {
        var div = document.createElement("div");
        div.style.top = 0;
        div.style.right = 0;
        div.style.zIndex = 9999;
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        div.style.height = height + "px";
        return div;
      }
      function paddingDiff(col) {
        if (getStyleVal(col, "box-sizing") == "border-box") return 0;
        var padLeft = getStyleVal(col, "padding-left");
        var padRight = getStyleVal(col, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
      }
      function getStyleVal(elm, css) {
        return window.getComputedStyle(elm, null).getPropertyValue(css);
      }
    },
    async getBAQHeader() {
      this.loading = true;
      await callBAQ("DSPMntEquip", {
        CustNum: "2",
      }).then((res) => {
        this.loading = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.headerTitle = returnData.h), (this.headerItems = returnData.i);
          setTimeout(() => {
            this.setResize();
          }, 500);
        }
      });
    },
    async getBAQDetail(item) {
      this.loadingDtl = true;
      await callBAQ("DSP-MTN-EquipmentLocation", {
        Equipment: item.Equip_EquipID,
      }).then((res) => {
        this.loadingDtl = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.detailTitle = returnData.h), (this.detailItems = returnData.i);
          setTimeout(() => {
            window.scrollTo({
              top: document.getElementById("detail").offsetTop,
              left: 0,
              behavior: "smooth",
            });
            this.setResize();
          }, 500);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/standard.scss";
</style>
